
import React from "react";
import { convertAdUrl } from "../../utils/utils";
import { useSelector } from "react-redux";

const ContinueWatchingModel = (props) => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);

  const ContinueWatchingHandler = async (value) => {
    if (value === "yes") {
      props?.playVideo(props?.videoDetails?.watched_duration);
      props?.setIsContinueWatchingModal(false)
  
    } else if (value === "no") {
      props?.playVideo();
      props?.setIsContinueWatchingModal(false)
    }
  };
  return (
    <div className="countinueWatching">
      <div className="overlayModal"></div>
      <div className="contents">
        <h1>Continue from where you stopped?</h1>
        <div className="buttons">
          <div
            className="buttonContainer"
            onClick={() => ContinueWatchingHandler("yes")}
          >
            <div className="background"></div>
            <button>Yes</button>
          </div>

          <div
            className="buttonContainer"
            onClick={() => ContinueWatchingHandler("no")}
          >
            <div className="background"></div>
            <button>No</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContinueWatchingModel;
