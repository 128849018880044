import axios from "axios";
import { getDeviceType, getBrowserType, fetchApiData, checkOperatingSystem, updateApiDataV2 } from "../utils/utils";
const qs = require("query-string");

export const fetchAppInfo = async (params) => {
  // const website = "https://fishonnetworktv.gizmott.com"; // For development
  // const website = "https://staging.iptvsolutionsgroup.com"; 
  // const website = "https://uptown.gizmott.com";
  const website = window.location.origin // For LIVE
  // const website = "https://watch.tonictvnet.com"
  // const website = "https://staging.justwatchme.tv";
  // const website = "https://bountyhunterd.com";
  // const website = "https://chicanohollywood.tv";
  // const website = "https://bountyhunterd.com";
  // const website = "https://hisadvocates.tv"
  // const website = "https://famigliadorotv.com"
  // const website = "https://mmatv.gizmott.com";
  // const website = "https://ceyflix.gizmott.com";
  // const website = "https://thehhntv.gizmott.com";
  // const website = 'https://royalktv.com';
  // const website = "https://thelaxxnetwork.com";
  // const website = 'https://dupontod.com';
  // const website = 'https://cpics.gizmott.com';
  // const website = "https://foundtv.gizmott.com";

  const customConfig = {
    headers: {
      website: website,
    },
    params: params && params,
  };
  return await axios.get(
    process.env.REACT_APP_API_URL + "config",
    customConfig
  );
};

export const fetchGeoInfo = async () => {
  return await axios.get("https://api.gizmott.com/api/v1/ipinfo");
};


export const updateExternalUser = async (appInfo, token) => {
  const api = "account/verify";
  const location = {
    path: "common",
    values: { token }
  }
  return await updateApiDataV2(appInfo, api, location)
}
export const emailRequestCheck = async (appInfo, id) => {
  const api = "account/request/check";
  const params = {
    id
  }
  return await fetchApiData(appInfo, api, params)
}

export const guestUser = async (appInfo) => {
  let countryCode = appInfo?.projectDetails?.geoInfo?.countryCode;
  let ipaddress = appInfo?.projectDetails?.geoInfo?.query;
  let deviceId = appInfo?.projectDetails?.device_id;
  let pubid = appInfo?.projectDetails?.projectConfig?.pubid;
  let channelid = appInfo?.projectDetails?.projectConfig?.channelid;
  const customConfig = {
    headers: {
      pubid: pubid,
      channelid: channelid,
      country_code: countryCode,
      device_type: "web",
      dev_id: deviceId,
      ip: ipaddress,
      ua: navigator.userAgent,
    },
  };
  return await axios.post(
    process.env.REACT_APP_API_URL + "account/register/guest",
    "",
    customConfig
  );
};

export const authenticate = (appInfo) => {
  let uId = localStorage.getItem("gid");
  let countryCode = appInfo?.projectDetails?.geoInfo?.countryCode;
  let ipaddress = appInfo?.projectDetails?.geoInfo?.query;
  let deviceId = appInfo?.projectDetails?.device_id;
  let pubid = appInfo?.projectDetails?.projectConfig?.pubid;
  let channelid = appInfo?.projectDetails?.projectConfig?.channelid;
  let user_id = localStorage.getItem("userId");
  if (user_id) {
    uId = user_id;
  }
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": true,
      crossorigin: true,
      uid: uId,
      pubid: pubid,
      country_code: countryCode,
      channelid: channelid,
      dev_id: deviceId,
      ip: ipaddress,
      device_type: "web",
    },
  };
  return axios
    .get(process.env.REACT_APP_API_URL + "account/authenticate", customConfig)
    .then((response) => {
      if (response?.data?.message === "Invalid user") {
      } else {
        return response.data;
      }
    });
};

export const authenticate2 = async (appInfo) => {
  const url = `account/authenticate`;
  return await fetchApiData(appInfo, url);
};

export const applaunchEvent = async (event, appInfo) => {
  let sessionId = appInfo?.sessionId;
  let uId = localStorage.getItem("gid");
  let user_id = localStorage.getItem("userId");
  if (user_id) {
    uId = user_id;
  }
  let device_id = appInfo?.projectDetails?.device_id;
  let ctimestamp = Date.now().toString();
  let ctime = ctimestamp.slice(0, 10);
  let appid = appInfo?.projectDetails?.projectConfig?.app_id;
  let pubid = appInfo?.projectDetails?.projectConfig?.pubid;
  let channelid = appInfo?.projectDetails?.projectConfig?.channelid;
  const requestBody = {
    app_id: appid,
    timestamp: ctime,
    device_id: device_id,
    event_type: event,
    publisherid: pubid,
    user_id: uId,
    channel_id: channelid,
    session_id: sessionId,
  };
  var token = appInfo?.accessToken;
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": true,
      crossorigin: true,
      "access-token": token,
    },
  };

  return await axios.post(
    "https://analytics.poppo.tv/event",
    qs.stringify(requestBody),
    customConfig
  );
};

export const analytics = (Response_uid, appInfo, Response_email, user_name) => {
  let countryCode = appInfo?.projectDetails?.geoInfo?.countryCode;
  localStorage.setItem("device_analytics", "true");
  let uId = Response_uid ? Response_uid : localStorage.getItem("gid");
  let user = localStorage.getItem("userId")

  let user_id = Response_uid ? Response_uid : localStorage.getItem("userId");
  if (user_id) {
    uId = user_id;
  }
  let deviceId = appInfo?.projectDetails?.device_id;
  var token = appInfo?.accessToken;
  let sessionId = appInfo?.sessionId;
  let userEmail = Response_email ? Response_email : "";

  let appid = appInfo?.projectDetails?.projectConfig?.app_id;
  let deviceType = getDeviceType();
  let ctimestamp = Date.now().toString();
  let ctime = ctimestamp.slice(0, 10);
  let browserType = getBrowserType();
  let pubid = appInfo?.projectDetails?.projectConfig?.pubid;
  let channelid = appInfo?.projectDetails?.projectConfig?.channelid;
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": true,
      crossorigin: true,
      "access-token": token,
    },
  };
  let currentLocation = appInfo?.projectDetails?.geoInfo;
  const data = {
    session_id: sessionId,
    device_id: deviceId,
    publisherid: pubid,
    channelid: channelid,
    app_id: appid,
    user_id: uId,
    ip_address: currentLocation?.query,
    ua: navigator.userAgent,
    timestamp: ctime,
    country: currentLocation?.country,
    device_type: deviceType,
    city: currentLocation?.city,
    latitude: currentLocation?.lat,
    longitude: currentLocation?.lon,
    width: window.innerWidth,
    height: window.innerHeight,
    device_make: navigator.userAgent,
    device_model: navigator.userAgent,
    browser: browserType,
    user_name: user ? user_name : "guest",
    user_email: userEmail ? userEmail : "",
    user_contact_number: "",
    country_code: countryCode,
  };
  return axios
    .post("https://analytics.poppo.tv/device", qs.stringify(data), customConfig)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return [];
    });
};

export const analytics2 = async (Response_uid, appInfo, Response_email, user_name) => {
  let countryCode = appInfo?.projectDetails?.geoInfo?.countryCode;
  let uId = Response_uid ? Response_uid : localStorage.getItem("gid");
  let user = localStorage.getItem("userId")
  let user_id = Response_uid ? Response_uid : localStorage.getItem("userId");
  if (user_id) {
    uId = user_id;
  }
  let deviceId = appInfo?.projectDetails?.device_id;
  let sessionId = appInfo?.sessionId;
  let userEmail = Response_email ? Response_email : "";

  let appid = appInfo?.projectDetails?.projectConfig?.app_id;
  let deviceType = getDeviceType();
  let ctimestamp = Date.now().toString();
  let ctime = ctimestamp.slice(0, 10);
  let browserType = getBrowserType();
  let pubid = appInfo?.projectDetails?.projectConfig?.pubid;
  let channelid = appInfo?.projectDetails?.projectConfig?.channelid;
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": true,
      crossorigin: true,
    },
  };
  let currentLocation = appInfo?.projectDetails?.geoInfo;
  const data = {
    session_id: sessionId,
    device_id: deviceId,
    publisherid: pubid,
    channelid: channelid,
    app_id: appid,
    user_id: uId,
    ip_address: currentLocation?.query,
    ua: navigator.userAgent,
    timestamp: ctime,
    country: currentLocation?.country,
    device_type: deviceType,
    city: currentLocation?.city,
    latitude: currentLocation?.lat,
    longitude: currentLocation?.lon,
    width: window.innerWidth,
    height: window.innerHeight,
    device_make: navigator.userAgent,
    device_model: navigator.userAgent,
    browser: browserType,
    user_name: user ? user_name : "guest",
    user_email: userEmail ? userEmail : "",
    user_contact_number: "",
    country_code: countryCode,
  };
  return await axios.post(
    "https://analytics.poppo.tv/device",
    qs.stringify(data),
    customConfig
  );
};

export const getPlayerToken = async (appInfo, id) => {
  const api = "playlistV2/generateToken";
  const params = {
    id: id
  }
  return await fetchApiData(appInfo, api, params)
};

export const affliateAnalytics = async (appInfo) => {
  let deviceType;
  if (checkOperatingSystem() === "android") {
    deviceType = "Android"
  } else if (checkOperatingSystem() === "iPhone") {
    deviceType = "iPhone"
  }
  let pubid = appInfo?.projectDetails?.projectConfig?.pubid;
  let channelid = appInfo?.projectDetails?.projectConfig?.channelid;
  const url = localStorage.getItem("affliateUrl")
  const unique = deviceType && appInfo?.projectDetails?.geoInfo?.query + deviceType;
  let params = { url: url, source_application: document.referrer, unique_device_id: unique }
  let uid = localStorage.getItem("gid");
  let user_id = localStorage.getItem("userId");
  if (user_id) {
    uid = user_id;
  }
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": true,
      crossorigin: true,
      pubid: pubid,
      channelid: channelid,
      uid: uid,
      device_type: "web"
    },
    params: params
  };

  return await axios.get(
    "https://api.gizmott.com/api/v1/affiliate/analytics",
    customConfig
  );
};

export const updateWatchlistData = async (appInfo, showId, flag) => {
  const api = `watchlist/show/${showId}/${flag}`;
  return await fetchApiData(appInfo, api);
};

export const getUserSubscription = async (appInfo) => {
  const api = "subscription/user";
  return await fetchApiData(appInfo, api);
};

export const logoutAll = async (appInfo) => {
  const api = "account/logoutall";
  return await fetchApiData(appInfo, api);
};

export const fetchEventDetails = async (appInfo, eventId) => {
  const api = "subscription/event";
  const params = {
    event_id: eventId
  }
  return await fetchApiData(appInfo, api, params)
}
