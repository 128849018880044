import React, { useEffect, useRef, useState } from "react";
import { getVideoDetails } from "./service"
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import VideoJS from '../../Components/VideoJsPlayer/VideoJS';
import { convertAdUrl, getEventInfo, imageUrlCheck, onVideoPlayFunction } from "../../utils/utils";
import { getVideoSubscription } from "../Subscription/service";
import { getUserSubscriptionData } from "../../Redux/UserSubscriptionDetails/userSubscriptionSlice";
import { getPlayerToken, updateVideoWatchlistData, getUserSubscription, updateWatchlistData, authenticate } from "../../network/service";
import { getMoreInfoOther } from "../../Redux/MoreInfo/moreInfoOtherSlice";
import { getMoreInfo } from "../../Redux/MoreInfo/moreInfoSlice";
import { getUser } from "../../Redux/UserSlice/UserSlice";
import { getAccessToken } from "../../Redux/AToken/ATokenSlice";
import WatchWithoutAdsModal from "../../Components/Modals/WatchWithoutAdsModal";
import ContinueWatchingModel from "../../Components/Modals/ContinueWatchingModel";
import Loading from "../../Components/Loading/Loading";
import { ReactComponent as LeftArrow } from "../../assets/Icons/leftArrow.svg";
import ShowCard from "../../Components/ShowCard/ShowCard";
import Select from "react-select";
import Comment from "./Components/Comment"
import Autoplay from "./Components/Autoplay";
import LogoutAllModal from "../../Components/Modals/LogoutAllModal";

// import { createBrowserHistory } from 'history';

let debounce = true;
var videoStarted = false;
let seconds = 60;
let prevTime;
let videoPlaying = false;

function VideoPlayer() {
    const location = useLocation()
    const videoId = location?.state?.videoDetails?.video_id;
    const {vanityUrl} = useParams()
    const datas = location?.state?.data;
    const showName = location?.state?.showname;

    const projectInfo = useSelector((state) => state?.projectInfo?.value);
    const accessToken = useSelector((state) => state?.accessToken?.value);
    // const userSubscriptionData = useSelector((state) => state?.userSubscription?.value);
    const sessionId = useSelector((state) => state?.sessionId?.value);
    const user = useSelector((state) => state?.user?.value);
    const isLive = location?.state?.isLive;
    const isMoreInfoOther = useSelector((state) => state?.moreInfoOther?.value);

    const [videoUrl, setVideoUrl] = useState();
    const [adUrl, setAdUrl] = useState("");
    const [videoDetails, setVideoDetails] = useState();
    const [videoTitle, setVideoTitle] = useState("")
    const [subscriptions, setSubscriptions] = useState();
    const [loading, setLoading] = useState(true);
    const [watchListStatus, setWatchListStatus] = useState();
    const [vPlayer, setVPlayer] = useState();
    const [subscribedUser, setSubscribedUser] = useState(true);
    const [isContinueWatchingModal, setIsContinueWatchingModal] = useState(false);
    const [watchWithoutAds, setWatchWithoutAds] = useState(false);
    const [showVideo, setShowVideo] = useState(false);
    const [subtitleInfo, setSubTitleInfo] = useState();
    const [closedCaptionInfo,setClosedCaptionInfo] = useState()
    const [watchedDuration, setWatchedDuration] = useState();
    const [isAutoplayView, setIsAutoplayView] = useState(false);
    const [watchedTime, setWatchedTime] = useState(0)
    const [multiLanguageOptions, setMultiLanguageOptions] = useState([])
    const [selectedAudioLanguage, setSelectedAudioLanguage] = useState({})
    const [upNext, setUpNext] = useState([])
    const [isFree, setIsFree] = useState(false);
    const [isPremium, setIsPremium] = useState(false);
    const [userSubscriptionData, setUserSubscriptionData] = useState([]);
    const [modalType, setModalType] = useState({ type: "logoutAll" });
    const [logoutModal, setLogoutModal] = useState(false);

    const playerRef = useRef(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const showDetails = location?.state?.showDetails
    const showId = showDetails?.show_id;
    const videoType = location?.state?.showDetails?.type === "linear_event" ? "event" : "video";
    let showUniqueId = isMoreInfoOther?.showId ? isMoreInfoOther?.showId : showId;

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: 'transparent',
            '&:hover': {
                borderColor: 'grey',
            },

        }),

        menu: (provided) => ({
            ...provided,
            top: 'auto',
            bottom: '100%',
            backgroundColor: 'grey',
        }),

        singleValue: (provided) => ({
            ...provided,
            color: 'white',
        }),

        placeholder: (provided) => ({
            ...provided,
            color: 'white',
        }),
    };

    const appInfo = {
        projectDetails: projectInfo,
        accessToken: accessToken,
        sessionId,
    };
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
        setVPlayer(null)
        setLoading(true)

        fetchVideoDetails();
    }, [location?.pathname]);

    useEffect(() => {
        window.scroll(0, 0)
        setVPlayer(null)
        if (videoDetails) {
            handleVideoPlayback();
        }

    }, [subscriptions]);

    useEffect(() => {
        setVPlayer(null);
        if (videoUrl) {
            const videoJsOptions = {
                autoplay: true,
                controls: true,
                responsive: true,
                fluid: true,
                controlBar: {
                    pictureInPictureToggle: false,
                },
                sources: [
                    {
                        src: `${videoUrl}`,
                        type: "application/x-mpegURL",
                    },
                ],
            };

            setVPlayer(
                <VideoJS
                    options={videoJsOptions}
                    onReady={handlePlayerReady}
                    adUrl={adUrl ? adUrl : null}
                    subtitles={subtitleInfo}
                />
            );
        }
    }, [videoUrl]);

    const fetchVideoSubscription = async (videoId) => {
        const type = "video";
        try {
            return await getVideoSubscription(appInfo, videoId, type);
        } catch (err) { }
    };

    const handleClose = () => {
        document.body.className = "hideScroll";
        if (isMoreInfoOther?.isModal) {
            if (isMoreInfoOther?.isFromSearch) {
                if (location?.state?.isFromHome) {
                    navigate("/home");
                }
                setTimeout(() => {
                    dispatch(
                        getMoreInfoOther({
                            isModal: false,
                            showId: null,
                            isFromSearch: false,
                        })
                    );
                }, 600);
            } else {
                setTimeout(() => {
                    dispatch(
                        getMoreInfoOther({
                            isModal: false,
                            showId: null,
                        })
                    );
                }, 600);
            }
        } else {
            setTimeout(() => {
                dispatch(
                    getMoreInfo({
                        moreInfo: false,
                    })
                );
            }, 500);
        }

    };
    const redirectToLogin = () => {
        dispatch(
            getUser({
                user: null,
            })
        );
        localStorage.removeItem("userId");
        tokenAuthenticate();
        navigate("/login", {
            state: { path: location?.pathname, showId: showUniqueId },
        });
    };
    const fetchUserSubscriptionDetails = async () => {
        const userSubResponse = await getUserSubscription(appInfo);
        if (userSubResponse?.status === 200) {

            setUserSubscriptionData(userSubResponse?.data);
            dispatch(
                getUserSubscriptionData({
                    userSubscription: userSubResponse?.data,
                })
            );
        }
    };

    const updateVideoAnalytics = async (event, time) => {
        const updateResponse = await onVideoPlayFunction(
            appInfo,
            videoDetails,
            event,
            time,
            "0",
            location?.state?.categories,
            showDetails?.show_id
        );
    };

    const fetchVideoDetails = async () => {
        try {
            await fetchUserSubscriptionDetails();
            const response = await getVideoDetails(vanityUrl, appInfo);
            if (response?.status === 200) {
                setMultiLanguageOptions(response.data.data.audio_languages);
                const videoSubResponse = await fetchVideoSubscription(response?.data?.data?.video_id);
                if (videoSubResponse?.status === 200) {
                    setVideoDetails(response?.data?.data);
                    setVideoTitle(response?.data?.data?.video_title)
                    setUpNext(response?.data?.data?.up_next)
                    setSubscriptions(videoSubResponse?.data?.data);
                    setLoading(false)
                    if (response?.data?.data?.watchlist_flag === 1) setWatchListStatus("added");
                } else {
                    setWatchListStatus("removed");
                }
            }
        } catch (err) {
            setLoading(false);
        }
    };
    const multiLanguageDropdownHandler = async (selectedOption) => {
        setSelectedAudioLanguage({ value: selectedOption.value, label: selectedOption.label, video_name: selectedOption.video_name })
        setWatchedTime(playerRef.current.currentTime())
        setVideoTitle(selectedOption.video_title)
        createVideoUrl(selectedOption)
    }
    const tokenAuthenticate = async () => {
        const response = await authenticate(appInfo);
        if (response?.status === 200) {
            dispatch(
                getAccessToken({
                    accessToken: response?.data?.token,
                })
            );
        }
    };

    const subscriptionCheck = (userSub, videoSub) => {
        return userSub?.some((user) => videoSub?.some((video) => user?.sub_id === video?.subscription_id));
    };

    const handleVideoPlayback = async () => {
        if (projectInfo?.projectConfig?.config?.REGISTRATION_MANDATORY === "true" && !user) {
            navigate("/login", { state: { ...location.state, path: location?.pathname, showId: showId, isFrom: true } });
            return;
        }
        if (logoutLimitCheck()) {
            return;
        }
        if (subscriptions?.length === 0) {
            if (projectInfo?.projectConfig?.config?.REGISTRATION_MANDATORY === "true") {
                if (!user) {
                    navigate("/login", { state: { ...location.state, path: location?.pathname, showId: showId, isFrom: true } });
                }
                else
                    if (projectInfo?.projectConfig?.config?.AD_REQUIRED === "true") {
                        setAdUrl(convertAdUrl(videoDetails, showDetails, appInfo, isLive));
                        playVideo();
                    } else {
                        playVideo();
                    }
            }
            else if (projectInfo?.projectConfig?.config?.AD_REQUIRED === "true") {
                if (userSubscriptionData?.login_needed === false) {
                    handleClose();
                    setAdUrl(convertAdUrl(videoDetails, showDetails, appInfo, isLive));
                    playVideo();
                } else if (userSubscriptionData?.login_needed === true) {
                    setModalType({ type: "videoWatchLimitExpired" });
                    setLogoutModal(true);
                }
                else {
                    setAdUrl(convertAdUrl(videoDetails, showDetails, appInfo, isLive));
                    playVideo();
                }
            }
            else {
                if (userSubscriptionData?.login_needed === false) {
                    handleClose();
                    playVideo();
                } else if (userSubscriptionData?.login_needed === true) {
                    setModalType({ type: "videoWatchLimitExpired" });
                    setLogoutModal(true);
                }
                else {
                    playVideo();
                }
            }
        }
        else if (subscriptions?.length > 0 && videoDetails?.free_video) {
            logoutLimitCheck();
            if (subscriptionCheck(userSubscriptionData?.data, subscriptions)) {
                playVideo();
            } else {
                setWatchWithoutAds(true);
            }
        }
        else {
            logoutLimitCheck();
            if (subscriptionCheck(userSubscriptionData?.data, subscriptions)) {

                playVideo();
            } else {
                if (!user) {

                    navigate("/login", { state: { ...location.state, path: location?.pathname, showId: showId, isFrom: true } });
                } else {
                    navigate("/subscription", { state: { videoId: videoDetails?.video_id } });
                }
            }

        }
    };

    const logoutLimitCheck = () => {
        if (userSubscriptionData?.forcibleLogout === true) {
            setLogoutModal(true);
            setModalType({
                type: "logoutAll",
                heading: "You’ve reached the maximum Device limit.Do you want to logout from all devices",
            });
            return true;
        } else if (userSubscriptionData?.session_expired === true) {
            setModalType({ type: "redirect" });
            setLogoutModal(true);
            setTimeout(() => {
                handleClose();
                redirectToLogin();
            }, 1000);
            return true;
        }
        return false;
    }

    const createVideoUrl = async (video) => {
        const response = await getVideoDetails(video.value, appInfo);
        setUpNext(response?.data?.data?.up_next)
        const arr = video.video_name?.split("/").reverse();
        const playerTokenData = await fetchPlayerToken(arr[1]);

        if (playerTokenData) {
            setVPlayer(null);
            setVideoUrl(
                "https://api.gizmott.com/api/v1/playlistV2/playlist.m3u8?id=" +
                arr[1] +
                "&token=" +
                playerTokenData +
                "&type=" +
                videoType +
                "&pubid=" +
                projectInfo?.projectConfig?.pubid
            );
        }
    }

    const playVideo = async (watchedLength) => {
        setShowVideo(true);
        if (watchedLength) {
            setWatchedDuration(watchedLength);
        }
        setSubTitleInfo(videoDetails?.subtitles);
        setClosedCaptionInfo(videoDetails?.closed_captions);
        const arr = videoDetails?.video_name?.split("/").reverse();
        const playerTokenData = await fetchPlayerToken(arr[1]);

        if (playerTokenData) {
            setVPlayer(null);
            setVideoUrl(
                "https://api.gizmott.com/api/v1/playlistV2/playlist.m3u8?id=" +
                arr[1] +
                "&token=" +
                playerTokenData +
                "&type=" +
                videoType +
                "&pubid=" +
                projectInfo?.projectConfig?.pubid
            );
        }
    };

    const fetchPlayerToken = async (videoId) => {
        const playerTokenResponse = await getPlayerToken(appInfo, videoId);
        if (playerTokenResponse?.status === 200) {
            return playerTokenResponse?.data?.data;
        }
    };

    const handlePlayerReady = (player) => {
        playerRef.current = player;
        player.on("play", () => {
            let event = videoStarted == true ? "POP09" : "POP02";
            videoStarted = true;
            videoPlaying = true;
            updateVideoAnalytics(event, player.currentTime());
        });
        player.on("loadedmetadata", () => {
            // player.pause();
            if (watchedTime > 0) {
                player.currentTime(watchedTime.toString());
                player.play();
                videoPlaying = true;
            } else if (watchedDuration > 0) {
                player.currentTime(watchedDuration.toString());
                player.play();
                videoPlaying = true;
            }
            else {
                player.play();
                videoPlaying = true;
            }
        });
        player.on("timeupdate", function (e) {
            let event = "POP03";
            videoPlaying = true;
            var hasPlayedTime = player.currentTime();
            var intPlayedTime = parseInt(hasPlayedTime, 10);
            var isSixty = intPlayedTime % seconds === 0 && intPlayedTime !== 0;
            if (isSixty && debounce) {
                debounce = false;
                prevTime = intPlayedTime;
                updateVideoAnalytics(event, player.currentTime());
            } else {
                if (debounce == false && prevTime != intPlayedTime) {
                    debounce = true;
                }
            }
        });

        player.on("pause", () => {
            let event = "POP04";
            videoPlaying = true;
            updateVideoAnalytics(event, player.currentTime());
        });
        player.on("ended", () => {
            let event = "POP05";
            videoPlaying = false;
            // episodeCount = findIndex() + 1;
            updateVideoAnalytics(event, player.currentTime());
            if (videoDetails?.up_next?.length > 0) {
                setIsAutoplayView(true);
            }
        });
        player.on("dispose", () => {
            videoPlaying = false;
            videoStarted = false;
            player.pause();
        });
        player.on('keydown', (e) => {
            if (e.which === 32 || e.which === 13) {
                e.preventDefault();
                if (player.paused()) {
                    player.play();
                } else {
                    player.pause();
                }
            }
        });

        player.on('keydown', (e) => {
            if (e.which === 37) {
                e.preventDefault();
                player.currentTime(player.currentTime() - 10);
            } else if (e.which === 39) {
                e.preventDefault();
                player.currentTime(player.currentTime() + 10);
            }
        });
        player.on('keydown', (e) => {
            if (e.which === 70 || e.which === 102) {
                e.preventDefault();
                player.requestFullscreen();
            }
        });

        player.on('keydown', (e) => {
            if (e.which === 38) {
                e.preventDefault();
                player.volume(player.volume() + 1);
            } else if (e.which === 40) {
                e.preventDefault();
                player.volume(player.volume() - 0.1);
            }
        });

        player.on('keydown', (e) => {
            if (e.which === 77 || e.which === 109) {
                e.preventDefault();
                player.muted(!player.muted());
            }
        });
        player.ready(() => {
            player.el().focus();
        });
    };

    const goBack = () => {
        if (location.state.backURL) {
            navigate(location.state.backURL);
        } else {
            navigate(-1);
        }

    };

    if (loading) {
        return <Loading />;
    }
    return (
        <div className='videoPlayerContainer'>
            <div className='wrapper'>
                {watchWithoutAds && !isPremium && (
                    <WatchWithoutAdsModal
                        setWatchWithoutAds={setWatchWithoutAds}
                        showDetails={showDetails}
                        appInfo={appInfo}
                        setAdUrl={setAdUrl}
                        videoDetails={videoDetails}
                        watchDuration={watchedDuration}
                        playVideo={playVideo}
                        setIsContinueWatching={setIsContinueWatchingModal}
                    />
                )}
                {!watchWithoutAds && isContinueWatchingModal && (
                    <ContinueWatchingModel
                        setIsContinueWatchingModal={setIsContinueWatchingModal}
                        videoDetails={videoDetails}
                        playVideo={playVideo}
                        showDetails={showDetails}
                        appInfo={appInfo}
                        setAdUrl={setAdUrl}
                    />
                )}
                {logoutModal && (
                    <LogoutAllModal
                        showId={showUniqueId}
                        appInfo={appInfo}
                        setLogoutModal={setLogoutModal}
                        modalType={modalType}
                        videoDetails={videoDetails}
                        handleClose={handleClose}
                    />
                )}
                <div className="arrowContainer" onClick={goBack}>
                    <h3>
                        <LeftArrow />
                    </h3>
                </div>

                <div className="playerContainer">
                    {vPlayer && vPlayer}
                    {
                        isAutoplayView &&
                        <Autoplay episodes={videoDetails?.up_next} setIsAutoplayView={setIsAutoplayView} />
                    }
                </div>
                <div className="multiAudio" >
                    {multiLanguageOptions?.length > 1 &&
                        <div className="selectContainer">
                            <Select
                                styles={customStyles}
                                options={multiLanguageOptions.map((video) => ({
                                    value: video.video_id,
                                    label: video.language_name,
                                    video_name: video.video_name,
                                    video_title: video.video_title
                                }))}
                                placeholder={"Choose Language..."}
                                onChange={multiLanguageDropdownHandler}
                                value={selectedAudioLanguage && Object.keys(selectedAudioLanguage)?.length !== 0 ? selectedAudioLanguage : null}
                            />
                        </div>}
                </div>

                <div className="videoMetaData">
                    <div className="head">
                        <h1 className="videoTitle">{videoTitle}</h1>
                    </div>
                </div>

                <div>
                    {upNext.length > 0 && (
                        <div className="upNextContainer">
                            <h1 className="heading">Up Next {videoDetails?.season && `in Season ${videoDetails?.season}`} </h1>
                            <div className="upNext" onClick={() => setIsAutoplayView(false)}>
                                {upNext.map((video) => (
                                    <div className={projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION === "PORTRAIT" ? "itemPortrait" : "item"}>
                                        <ShowCard data={video} type="Episode" />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>

                {projectInfo?.projectConfig?.config?.COMMENTS_REQUIRED === "true" &&
                    <div className="wrapper">
                        <Comment videoId={videoDetails?.video_id} projectInfo={projectInfo} />
                    </div>
                }

            </div>
        </div>
    )
}

export default VideoPlayer
